<template>
  <div class="invite-friends">
    <div class="invite-friends-title">
      <div class="invite-friends-title-text">邀请好友获得奖励</div>
      <div class="invite-friends-title-desc">
        好友提交邀请码并完成答题，邀请码所有者即可获得1元返现
      </div>
    </div>

    <div class="invite-friends-content">
      <div class="invite-friends-content-item">
        <div class="invite-friends-content-item-title">我的邀请码</div>
        <div class="invite-friends-content-item-info" v-if="isInit">
          <div v-if="inviteCode" class="mycode">
            {{ inviteCode }}
            <du-icon
              @click="handleCopyInviteCode"
              name="copy"
              color="rgba(255, 255, 255, 0.4)"
              :extStyle="{ fontSize: '5.333333333333334rem', marginLeft: '2.1333333333333333rem' }"
            />
          </div>
          <!-- v-else -->
          <DuButton
            v-else
            color="white"
            :ext-style="{ width: '32rem' }"
            @click="handleGenerateInviteCode"
            >点击获取</DuButton
          >
        </div>
      </div>
    </div>

    <div class="invite-friends-content">
      <div class="invite-friends-content-item">
        <div class="invite-friends-content-item-title">填写邀请码</div>
        <div class="invite-friends-content-item-info">
          <input
            class="codeinput"
            type="text"
            v-model="othersInviteCode"
            placeholder="输入邀请码"
          />
          <span
            :class="`codeconfirm ${
              othersInviteCode ? 'codeconfirm--active' : ''
            }`"
            @click="handleConfirmInviteCode"
            >确认</span
          >
        </div>
      </div>
    </div>

    <div class="invite-friends-rule">
      开通微信收款账户即可获取专属邀请码，分享给好友一起探索临界大陆。受邀好友需完成以下步骤即视为邀请成功：
      <div class="indent">1. 注册账号并填写你的邀请码</div>
      <div class="indent">2. 通过临界入站答题</div>
      <br />
      <div>奖励机制：</div>
      <div class="indent">
        <span class="dot" /><span>&nbsp;每成功邀请1人立得2元奖励</span>
      </div>
      <div class="indent">
        <span class="dot" /><span
          >&nbsp;每日最高可获20元返现（限前10位有效邀请）</span
        >
      </div>
      <div>
        注：受邀好友需在注册30天内填写邀请码并完成答题流程方可计入有效邀请。
      </div>
      <br />
      <div>温馨提示：</div>
      <div>
        我们致力维护良好的社群环境，如出现异常注册行为，相关账号可能会收到服务限制。
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { DuButton, DuIcon } from "dangoui";
import {
  showSuccessToast,
  closeToast,
  showFailToast,
  showConfirmDialog,
} from "vant";
import { REQUEST_OK } from "@/constant/network";
import jb from "@frontend/effuse";
import InviteApi from "@/apis/invite";
import { getStorageToken } from "@/utils/authorize";
import { useUsersStore } from "@/store/user";
import { storeToRefs } from "pinia";

const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

const inviteCode = ref("");
const isInit = ref(false);
const othersInviteCode = ref("");

const handleGenerateInviteCode = async () => {
  const wxCheckedRes = await InviteApi.getWxChecked({
    userId: userInfo.value.userId,
  });
  if (!wxCheckedRes.data.isCertificate) {
    showConfirmDialog({
      confirmButtonText: "去开通",
      showCancelButton: false,
      title: "获取邀请码",
      message: "开通微信收款账户即可获取专属邀请码",
    })
      .then(() => {
        jb.navigateTo({ url: `congress://pay/wx-shoufutong/register` });
      })
      .catch(() => {
        return;
      });
    return;
  }
  const res = await InviteApi.generateMyInviteCode();
  inviteCode.value = res.data.inviteCode;
  console.log("res==", res);
};

const handleCopyInviteCode = () => {
  showSuccessToast("复制成功");
  window.navigator.clipboard.writeText(inviteCode.value);
};

const handleConfirmInviteCode = async () => {
  if (!othersInviteCode.value) {
    showFailToast("请输入邀请码");
    return;
  }

  const res = await InviteApi.confirmInviteCode({
    inviteCode: othersInviteCode.value,
  });

  // if (res.code !== REQUEST_OK) {
  if (+res.code === 5000401) {
    showSuccessToast("即将跳转答题页面...");
    setTimeout(() => {
      closeToast();
      // const token = getStorageToken();
      const tempUrl = encodeURIComponent(
        `https://m.qiandaoapp.com/plugin/user-notice?pkg=1000&id=LINJIE_INV_NEW`
      );

      jb.navigateTo({
        url: `congress://hybrid?url=${tempUrl}&linjieEnv=false`,
      });
    }, 1500);
    return;
  } else if (res.code !== REQUEST_OK) {
    showFailToast(res.message);
    return;
  }

  showSuccessToast("绑定成功");
};

onMounted(() => {
  InviteApi.getMyInviteCode()
    .then((res) => {
      inviteCode.value = res.data.inviteCode;
    })
    .finally(() => {
      isInit.value = true;
    });
});
</script>

<style scoped lang="scss">
.invite-friends {
  background: url("https://assets.qiandaocdn.com/admin-upload/189be34e39ee7e494bebfb804ab22e56.png")
    no-repeat top center;
  background-size: 100% 1624rpx;
  min-height: 100vh;
  padding: 280rpx 30rpx 0 30rpx;
  &-title {
    text-align: center;
    &-text {
      color: white;
      font-weight: 500;
      font-size: 48rpx;
      line-height: 64rpx;
    }
    &-desc {
      margin-top: 8rpx;
      color: white;
      font-size: 20rpx;
      line-height: 22rpx;
    }
  }

  &-content {
    &-item {
      margin-top: 32rpx;
      padding: 30rpx;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 16rpx;
      background: rgba(255, 255, 255, 0.08);
      /* 💙 y:4 ; Blur=20（NFT 卡片投影） */
      box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
      &-title {
        color: white;
        font-size: 32rpx;
        line-height: 48rpx;
        font-weight: 500;
      }
      &-info {
        display: flex;
        align-items: center;
        margin-top: 40rpx;
        color: white;
        font-size: 24rpx;
        line-height: 36rpx;
        .mycode {
          display: flex;
          align-items: center;
          color: white;
          font-weight: 500;
          font-size: 48rpx;
          line-height: 64rpx;
        }

        .codeinput {
          display: flex;
          align-items: center;
          vertical-align: baseline !important;
          margin-right: 32rpx;
          display: flex;
          height: 116rpx;
          width: 526rpx;
          padding: 32rpx 24rpx;
          align-items: flex-start;
          flex: 1 0 0;

          font-size: 40rpx;
          line-height: 52rpx;
          border-radius: 16rpx;
          border: 2rpx solid rgba(255, 255, 255, 0.64);
        }
        .codeinput::placeholder {
          position: relative;
          top: 6rpx;
          color: rgba(255, 255, 255, 0.4);
          font-size: 40rpx;
          line-height: 52rpx;
          vertical-align: baseline !important;
        }
      }
      .codeconfirm {
        color: rgba(255, 255, 255, 0.4);
        font-size: 28rpx;
        line-height: 44rpx;
        &--active {
          color: white;
        }
      }
    }
  }
}

.invite-friends-rule {
  margin-top: 32rpx;
  color: rgba(255, 255, 255, 0.64);
  font-size: 24rpx;
  line-height: 36rpx;
}

.indent {
  text-indent: 1em;
}
.dot {
  position: relative;
}
.dot::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -0.9em;
  content: "·";
  font-size: 24px;
  margin-left: 5px;
}
</style>
