/**
 * Created by Terence on 2022/8/23 - 17:39
 * Description :
 */
import { rpNft, rpApi } from "@/http";

interface OrderData {
  orderId: string;
}

export default {
  getMyInviteCode(params: any) {
    return rpNft.post("/gw/account/get-invite-code", params);
  },
  generateMyInviteCode(params: any) {
    return rpNft.post("/gw/account/generate-invite-code", params);
  },
  confirmInviteCode(params: any) {
    return rpNft.post("/gw/account/set-invite-code", params);
  },
  getWxChecked(params: any) {
    return rpApi.get(
      "/supplier/grpc/shop/applyment/v3/certification_result",
      // "/supplier/grpc/user/applyment/v3/status",
      params
    );
  },
};
